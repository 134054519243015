// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
  },
};

class Blog_2016_10_14_Aca_Leaderguide_Rendezvous_Day_1 extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          This is just a short summary of some of the things we covered on the
          first day of the Leader/Guide Rendezvous.
        </p>
        <p>
          After a few brief items of business Rick took the Pro Guides and Rich
          stayed with the leaders and aspirants.
        </p>
        <h2>Retrievable Guide Line</h2>
        <p>
          We started with a demonstration on how to set a retrievable guide
          line.
        </p>
        <Image image={data._1} />
        <p>
          Traversing across – the rope is tied to the{' '}
          <GlossaryLink>harness</GlossaryLink>, looped to the{' '}
          <GlossaryLink>rapide</GlossaryLink> and back to the{' '}
          <GlossaryLink>rappel device</GlossaryLink>. As the guide backs up, the
          rope is fed out of the <GlossaryLink id="rope-bag">bag</GlossaryLink>.
        </p>
        <p>
          Because of the extra friction from the rope going through the rapide,
          the guide should consider rigging their rappel device with less
          friction to compensate.
        </p>
        <p>
          Once the guide crosses the hazard, he clips his{' '}
          <GlossaryLink>safety tether</GlossaryLink> to the{' '}
          <GlossaryLink>anchor</GlossaryLink> on the other side. He clips the
          <GlossaryLink>figure 8 on a bight</GlossaryLink> to the anchor, and
          ties the <GlossaryLink>bag end</GlossaryLink> of the rope to the
          anchor as well. If the anchors can handle it, consider tightening the
          rope with a <GlossaryLink>Munter hitch</GlossaryLink> on the bag end
          and then <GlossaryLink id="mule-hitch">muling</GlossaryLink> it off.
        </p>
        <Image image={data._2} />
        <p>
          Once the guide secures both strands of rope, the clients clip into
          both strands with their safety tether to cross.
        </p>
        <p>
          When all clients have passed the obstacle, the guide releases the
          bag-side rope, undoes and flakes the figure 8 on a bight, and
          retrieves the rope.
        </p>
        <h2>Descending with two ropes of different widths</h2>
        <p>
          Imagine Rich’s right hand is the rapide. The two ropes are secured
          with a <GlossaryLink>flat double overhand bend</GlossaryLink>. The
          canyoneer rappels on both strands. The thicker rope (tan rope in
          picture below) goes through the rapide. When on rappel, the thinner
          rope will tend to pass through the rappel device more quickly and the
          thicker rope will tend to pass through the rappel device more slowly.
          Consequently, the thicker rope will be pulled down and the thin rope
          pulled up. With the thicker rope going through the rapide and the
          thicker rope pulling through, the knot will eventually get caught by
          the rapide and the ropes will stop sliding.
        </p>
        <Image image={data._3} />
        <h2>On Rock Practice</h2>
        <p>
          We then left for on-rock practice and split into two groups. My group
          practiced converting a{' '}
          <GlossaryLink id="static-block-to-lower">
            static block to a lower
          </GlossaryLink>
          , <GlossaryLink id="haul-system">hauling</GlossaryLink> someone up on
          rappel, and <GlossaryLink>pick offs</GlossaryLink>. I only have a
          couple of pictures since I was helping with a station.
        </p>
        <Image
          caption="Hauling a canyoneer back up to the top of the rappel"
          image={data._4}
        />
        <h2>Static block to lower</h2>
        <p>
          Using a block and tackle and{' '}
          <GlossaryLink>mariners hitch</GlossaryLink> to lift a rappeller. The
          block and tackle is attached to the rope with a{' '}
          <GlossaryLink>Valdotain Tresse</GlossaryLink>. This allows the rescuer
          to release the static block and add a{' '}
          <GlossaryLink>belay device</GlossaryLink>. Then the rescuer can just
          leave the mariners hitch/VT on the rope as a{' '}
          <GlossaryLink>hands free backup</GlossaryLink> and lower the rappeller
          with the belay device.
        </p>
        <Image caption="Converting a static block to a lower" image={data._5} />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2016-10-14-aca-leaderguide-rendezvous-day-1')(
  Blog_2016_10_14_Aca_Leaderguide_Rendezvous_Day_1
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2016-10-14-aca-leaderguide-rendezvous-day-1/1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2016-10-14-aca-leaderguide-rendezvous-day-1/2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/blog/2016-10-14-aca-leaderguide-rendezvous-day-1/3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: {
        eq: "images/blog/2016-10-14-aca-leaderguide-rendezvous-day-1/4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: {
        eq: "images/blog/2016-10-14-aca-leaderguide-rendezvous-day-1/5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
